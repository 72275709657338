import $ from "jquery";
window.$ = window.jQuery = $;
import "slick-carousel";
export default class slick {
  init() {
    $("#featured-brands-slider").slick({
      autoplay: true,
      arrows: false,
      slidesToShow: 6,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
          },
        },
      ],
    });

    $("#testimonials-slider").slick({
      autoplay: true,
      arrows: true,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });

    $("#featuredcategories-slider").slick({
      autoplay: true,
      arrows: true,
      slidesToShow: 4,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });

    $("#recomendedproducts-slider").slick({
      arrows: false,
      slidesToShow: 3,
      infinite: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2.75,
            arrows: false,
          },
        },
      ],
    });

    $("#chisiamo-slider").slick({
      dots: false,
      arrows: false,
      slidesToShow: 1,
      asNavFor: $("#chisiamo-slider-nav"),
      fade: true,
    });

    $("#chisiamo-slider-nav").slick({
      dots: false,
      arrows: true,
      slidesToShow: 1,
      asNavFor: $("#chisiamo-slider"),
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            arrows: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
      ],
    });

    $("#banner-slider").slick({
      dots: false,
      arrows: true,
      slidesToShow: 1,
      prevArrow: $("#banner-slider-prev"),
      nextArrow: $("#banner-slider-next"),
    });
  }
}
